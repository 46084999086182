import type { PropsWithChildren } from 'react'

import Shimmer from '../Shimmer'
import SkeletonElement from '../SkeletonElement'
import styles from './button-skeleton.module.scss'

interface Props {
  bordered?: boolean
  sectioned?: boolean
  showActions?: boolean
  loading?: boolean
}

function ButtonSkeleton({
  bordered,
  children,
  loading = true,
}: PropsWithChildren<Props>) {
  return loading ? (
    <div
      className={styles.fsButtonSkeleton}
      data-store-button-skeleton
      data-bordered={bordered}
    >
      <SkeletonElement type="button" />

      <Shimmer />
    </div>
  ) : (
    <>{children}</>
  )
}

export default ButtonSkeleton
